<template>
  <div class="body_bgi box_1">
    <big-screen-header headerName="图表导航" />

    <div class="content_box">
      <div class="content_item_box">
        <dv-border-box-8 :dur="6" class="border_radius_10">
          <div class="title"><span>防疫</span></div>

          <div class="item_box">
            <div 
              class="item" 
              v-for="(item, index) in list1" :key="index"
              @click="goToPage(1, index)"
              >
              {{ item.name }}
            </div>
          </div>
        </dv-border-box-8>
      </div>
    </div>
  </div>
</template>

<script>
  import componentsMixin from '@/mixins/components.js'

  export default {
    name: '',
    mixins: [componentsMixin],
    data() {
      return {
        list1: [],
      }
    },
    mounted() {

    },
    methods: {
      // 跳转页面
      goToPage(val, index) {
        let path = ''
        if (val == 1) {
          path = this.list1[index].path
        } else if (val == 2) {
          path = this.list2[index].path
        } else if (val == 3) {
          path = this.list3[index].path
        } else if (val == 4) {
          path = this.list4[index].path
        } else if (val == 5) {
          path = this.list5[index].path
        } else if (val == 6) {
          path = this.list6[index].path
        } else {
          path = this.list7[index].path
        }
        
        this.$router.push({
          path: path
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  @import url('~@/assets/css/bigscreen.less');
  .activeColor { color: #2366fc !important; }

  .box_1 { background-size: 100% 100%; }
  .header { background-size: 100% 100%; }

  .content_box {
    width: 100%;
    height: calc(100% - 100px);
    padding: 10px;

    .content_item_box {
      width: 20% !important;
      height: 100%;
      // background-image: url('../../../../assets/images//chart-box/img3.png');
      // background-size: 100% 100%;

      .title { 
        width: 100%; 
        height: 6%; 
        text-align: center;
        display: flex;
        align-items: center;

        span {
          width: 100%;
          font-size: 18px; 
          font-weight: bold; 
          color: #82fefe; 
          cursor: pointer; 

        }
      }

      .item_box {
        width: 100%;
        height: 94%;
        overflow: auto;
        .item { width: 100%; text-align: center; color: #fff; cursor: pointer; margin-bottom: 5px; }
        .item:hover { color: #82fefe; }
      }
      .item_box::-webkit-scrollbar {
        width: 0px;
        height: 100%;
        background-color: #f5f5f5;
      }

      .item_box::-webkit-scrollbar-thumb {           
        border-radius: 10px;
        background: #2366fc;
      }
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    
  }
</style>